import { DateTime, Settings } from 'luxon';
import { DateTimeFormats } from '../../constants/datetime-formats';
import { Logger } from '../../logger';

Settings.throwOnInvalid = true;
declare module 'luxon' {
  interface TSSettings {
    throwOnInvalid: true;
  }
}

export type ToLuxonParam = Date | string | number | DateTime;
export type ToLuxonParamCallable = () => ToLuxonParam;
export type ToLuxonParamOrCallable = ToLuxonParam | ToLuxonParamCallable;

export function toLuxon(
  date: ToLuxonParam,
  parseFormats: string[] = [DateTimeFormats.TIME_12]
): DateTime {
  if (typeof date === 'string' && date.length > 0) {
    // This handles the case where the string is a unix timestamp
    if (parseInt(date).toString() === date) {
      Logger.error('Unexpected string timestamp', { date });
      return DateTime.fromMillis(parseInt(date));
    }

    for (const format of parseFormats) {
      try {
        return DateTime.fromFormat(date, format);
      } catch (e) {
        // ignore: Since the default format is TIME_12, we would expect this to fail
        // if the date is not in that format
      }
    }

    return DateTime.fromISO(date);
  }

  if (DateTime.isDateTime(date)) {
    return date;
  }

  if (typeof date === 'number') {
    return DateTime.fromMillis(date);
  }

  if (date instanceof Date) {
    return DateTime.fromJSDate(date);
  }

  throw new Error(`Invalid date: ${date}, typeof date: ${typeof date}`);
}
