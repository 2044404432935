export const DateTimeFormats = {
  // Formats like 12-19-2023 and 12-9-2023
  // NOTE: The use of the full-width slash character is intentional for spacing
  DATE: 'M／d／yyyy',

  // Formats like 2023-12-19
  DATE_SHORT: 'yyyy-MM-dd',

  /** Medium length date formatted based on local. e.g. Jan 1, 2021 (US) */
  DATE_MED: 'DD',

  /** Full date formatted based on local. e.g. January 1, 2021 (US) */
  DATE_MED_FULL: 'DDD',

  // Formats like Tuesday, December 19, 2023
  FULL_DATE: 'DDDD',

  /** Custom format for  human readable timestamp. e.g. Jan 1, 2021 at 1:00 PM*/
  TIMESTAMP: "DD 'at' t",

  // Formats like Dec 19
  DATE_MONTH: 'MMM d',

  // Formats like 5:28 PM
  TIME_12: 'h:mm a',

  // This can be used to parse: 2:00, 02:00, 17:00, etc
  TIME_FROM_24: 'H:mm',

  // Formats the time as 02:00, 17:00, etc
  TIME_24: 'HH:mm',

  // Unpadded minute, e.g. 1, 2
  MINUTE: 'm',

  // Formats like Tue, Dec 19, 2023
  DAY_DATE: 'EEE, MMM d, y',

  // Formats like Tue, Dec 19
  DAY_DATE_SHORT: 'EEE, MMM d',

  // Formats like Tue, Dec 19 at 5:28 PM
  DAY_DATE_TIME: "EEE, LLL dd 'at' h:mm a",

  // Formats like Tue, Dec 19, 2024 at 5:28 PM
  DAY_DATE_YEAR_TIME: "EEE, LLL dd, y 'at' h:mm a",

  // Formats like 5:28 PM on Dec 19
  TIME_DATE_MONTH: "h:mm a 'on' MMM d",

  // Formats like 2023-12-19 5:28 PM
  DATE_SHORT_TIME_12: 'yyyy-MM-dd h:mm a',

  // Formats like Tuesday, December 19, 2023 at 5:29 PM
  FULL_DATE_AND_TIME: "DDDD 'at' h:mm a",

  // Formats like Dec 19, 2023 at 5:29 PM
  MED_MONTH_DAY_YEAR_TIME: "MMM d, yyyy 'at' h:mm a",

  // Formats like Dec 19, 2023 at 5:29:05 PM EST
  DEBUG_TIMESTAMP: "DD 'at' ttt",

  // Formats like December, 2023
  MONTH_YEAR: 'MMMM, yyyy'
} as const;

export const dateTimeFormatNames = Object.keys(
  DateTimeFormats
) as (keyof typeof DateTimeFormats)[];
export type DateTimeFormatName = (typeof dateTimeFormatNames)[number];
