export const comparisonOperators = [
  '<',
  '<=',
  '≤',
  '=',
  '==',
  '!=',
  '≠',
  '>=',
  '≥',
  '>'
] as const;

export type ComparisonOperator = (typeof comparisonOperators)[number];

const COMPARISON_FUNCS: Record<
  ComparisonOperator,
  (a: any, b: any) => boolean
> = {
  '<': (a: any, b: any) => a < b,
  '<=': (a: any, b: any) => a <= b,
  '≤': (a: any, b: any) => a <= b,
  '>': (a: any, b: any) => a > b,
  '>=': (a: any, b: any) => a >= b,
  '≥': (a: any, b: any) => a >= b,
  '=': (a: any, b: any) => a === b,
  '==': (a: any, b: any) => a === b,
  '!=': (a: any, b: any) => a !== b,
  '≠': (a: any, b: any) => a !== b
};

export function compare(
  source: any,
  operator: ComparisonOperator,
  target: any
): boolean {
  target = target === 'true' ? true : target === 'false' ? false : target;

  const compareFn = COMPARISON_FUNCS[operator];
  if (compareFn === undefined) {
    throw new Error(`The operator '${operator}' is not a valid operator`);
  }

  return compareFn(source, target);
}
